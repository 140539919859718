import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
export const data = graphql`
  query {
    file(relativePath: { eq: "icepops.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Img fluid={props.data.file.childImageSharp.fluid} alt="Co-op students enjoying icepops outside on a warm day." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p>{`Welcome to the Co-op! We’re so glad you would like to enroll your child at our school. You will be joining a wonderful community.`}</p>
    <p>{`Our enrollment process begins in early January for the school year beginning the following September. First, we enroll current Co-op families and families returning to the Co-op after time away. Then we offer spots to new families. We welcome families to join the Co-op at any time as long as there the class you are interested in has available space.  `}</p>
    <p>{`To learn more about the enrollment process, or inquire whether space is available in our classes, please `}<a parentName="p" {...{
        "href": "mailto:mary.szubiak@watchungco-op.org"
      }}>{`email our VP of Enrollment, Mary Szubiak`}</a>{`. `}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      